<template>
  <k-display-group language-prefix="priceAgreement.periods.fields.salesAgreement">
    <KTextDisplay
      field="temporaryWorkerSalesAgreementTypeId"
      :value="temporaryWorkerSalesAgreementTypeText"
    />

    <KTextDisplay
      v-if="salesAgreement.temporaryWorkerSalesAgreementTypeId !== temporaryWorkerSalesAgreementType.MANUAL_INPUT"
      :value="amountFieldText"
      :field="amountFieldName"
    />
    <KTextDisplay
      v-if="salesAgreement.allowanceTypeId"
      field="allowanceTypeId"
      :value="allowanceTypeText"
    />
    <KTextDisplay
      v-if="salesAgreement.allowanceTypeId === allowanceType.ALLOWANCE_TYPE_CUSTOM"
      field="allowanceAmount"
      :value="$n(salesAgreement.allowanceAmount, 'factor')"
    />
  </k-display-group>
</template>

<script>
import temporaryWorkerSalesAgreementType from '@/application/enums/temporaryWorkerSalesAgreementType.js';
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import allowanceType from '@/application/enums/allowanceType.js';

export default {
  name: 'TemporaryWorkerSales',
  components: {
    KTextDisplay,
    KDisplayGroup,
  },
  props: {
    salesAgreement: {
      type: Object,
    },
  },
  computed: {
    allowanceType: () => allowanceType,
    temporaryWorkerSalesAgreementType: () => temporaryWorkerSalesAgreementType,
    temporaryWorkerSalesAgreementTypeText() {
      return this.$t(`priceAgreement.temporaryWorkerSalesAgreementType.${Object.keys(temporaryWorkerSalesAgreementType)
        .find(key => temporaryWorkerSalesAgreementType[key] === this.salesAgreement.temporaryWorkerSalesAgreementTypeId)}`);
    },
    allowanceTypeText() {
      const key = Object.keys(allowanceType)
        .find(key => allowanceType[key] === this.salesAgreement.allowanceTypeId);
      return this.$t(`priceAgreement.allowanceType.${key}`);
    },
    amountFieldName() {
      switch (this.salesAgreement.temporaryWorkerSalesAgreementTypeId) {
      case temporaryWorkerSalesAgreementType.SALES_RATE:
        return 'defaultAmount';
      case temporaryWorkerSalesAgreementType.CONVERSION_FACTOR:
      default:
        return 'factor';
      }
    },
    amountFieldText() {
      switch (this.salesAgreement.temporaryWorkerSalesAgreementTypeId) {
      case temporaryWorkerSalesAgreementType.SALES_RATE:
        return this.$n(this.salesAgreement.amount, 'currency');
      case temporaryWorkerSalesAgreementType.CONVERSION_FACTOR:
      default:
        return this.$n(this.salesAgreement.amount, 'factor');
      }
    },
  },
};
</script>
