<template>
  <k-display-group language-prefix="priceAgreement.periods.fields.purchaseAgreement">
    <k-text-display
      field="purchaseAgreementTypeId"
      :value="purchaseAgreementTypeText"
    />
    <k-text-display
      v-if="purchaseAgreement.purchaseAgreementTypeId === purchaseAgreementType.FIXED_RATE"
      field="rate"
      :value="$n(purchaseAgreement.rate, 'currency')"
    />
  </k-display-group>
</template>

<script>
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import { purchaseAgreementType } from '@/application/enums/purchaseAgreementType.js';

export default {
  name: 'Purchase',
  components: {
    KTextDisplay,
    KDisplayGroup,
  },
  props: {
    purchaseAgreement: {
      type: Object,
    },
  },
  computed: {
    purchaseAgreementType: () => purchaseAgreementType,
    purchaseAgreementTypeText() {
      return this.$t(`priceAgreement.purchaseAgreementType.${Object.keys(purchaseAgreementType)
        .find(key => purchaseAgreementType[key] === this.purchaseAgreement.purchaseAgreementTypeId)}`);
    },
  },
};
</script>
