<template>
  <k-display-group language-prefix="priceAgreement.periods.fields.premiumAgreement">
    <k-text-display
      field="premiumAgreementTypeId"
      :value="premiumAgreementTypeText"
    />
    <AgreementGrid
      v-if="showAgreementGrid"
      :agreements="premiumAgreement.grid"
      :fallback-amount="premiumAgreement.premiumAgreementTypeId === 1 ? 100 : 0"
      :use-percentages="premiumAgreement.premiumAgreementTypeId === 1"
    />
  </k-display-group>
</template>

<script>
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import { premiumAgreementType } from '@/application/enums/premiumAgreementType.js';
import AgreementGrid from '@/modules/priceAgreements/components/AgreementGrid.vue';

export default {
  name: 'SalesPremium',
  components: {
    AgreementGrid,
    KTextDisplay,
    KDisplayGroup,
  },
  props: {
    premiumAgreement: {
      type: Object,
    },
  },
  computed: {
    premiumAgreementTypeText() {
      return this.$t(`priceAgreement.premiumAgreementType.${Object.keys(premiumAgreementType)
        .find(key => premiumAgreementType[key] === this.premiumAgreement.premiumAgreementTypeId)}`);
    },
    showAgreementGrid() {
      return this.premiumAgreement.grid.length && [premiumAgreementType.PERCENTAGE, premiumAgreementType.AMOUNT]
        .includes(this.premiumAgreement.premiumAgreementTypeId);
    },
  },
};
</script>
