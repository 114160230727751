<template>
  <div class="agreement-grid ma-4">
    <v-row>
      <v-col>
        <v-expand-transition>
          <div v-if="hasErrors">
            <v-alert
              v-if="hasErrors"
              type="error"
              outlined
              class="my-4"
            >
              {{ $t('priceAgreement.grid.errors.overlapping') }}
            </v-alert>
          </div>
        </v-expand-transition>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="2"
        md="1"
      />
      <v-col>
        <v-row
          class="hour-legend body-2"
          style="width:105%"
        >
          <div
            v-for="hour in 25"
            :key="hour"
            :style="`width:${1/25*100}%`"
            class="hour text-center"
          >
            {{ toHourDisplayText(hour) }}
          </div>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="2"
        md="1"
        class="body-2 text-right px-0 week-legend"
      >
        <div class="weekday pr-3">
          {{ $t('priceAgreement.grid.fields.festiveDay') }}
        </div>
        <div
          v-for="i in 7"
          :key="i"
          class="weekday pr-3"
        >
          {{ $t(`global.weekDays.${i}`) }}
        </div>
      </v-col>
      <v-col class="pl-0">
        <div class="agreement-wrapper">
          <div class="agreement-grid white--text">
            <v-row
              v-for="(day, index) in days"
              :key="index"
              class="ma-0"
            >
              <div
                v-for="(agreement, index) in day"
                :key="index"
                :style="`width: ${agreement.percentage}%;`"
                :class="`${getColorByAmount(agreement.amount)} ${agreement.exceedsMaxPercentage? 'error' : ''}`"
                class="agreement"
              />
            </v-row>
          </div>
          <div class="time-grid">
            <v-row
              v-for="day in 8"
              :key="day"
              class="ma-0"
            >
              <div
                v-for="hour in 24"
                :key="hour"
                :style="`width:${1/24*100}%`"
                class="hour"
              />
            </v-row>
          </div>
        </div>

        <div class="legend mt-6">
          <v-row justify="center">
            <div
              v-for="item in amountColors"
              :key="item.amount"
              class="mx-2 text-center"
            >
              <div
                class="legend-block white--text rounded-circle elevation-5"
                :class="getColorByAmount(item.amount)"
              />
              <span class="body-2">
                {{ $n(item.amount, usePercentages ? '' : 'currency') }}
                {{ usePercentages ? ' %' : '' }}
              </span>
            </div>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'AgreementGrid',
  props: {
    colorVariant: {
      default: 'darken-3',
      type: String,
    },
    colors: {
      default: () => ['blue', 'deep-orange', 'teal', 'deep-purple', 'lime', 'blue-grey', 'green', 'pink', 'purple', 'indigo', 'cyan', 'amber', 'yellow', 'brown'],
      type: Array,
    },
    agreements: {
      required: true,
      type: Array,
    },
    fallbackAmount: {
      required: true,
      type: Number,
    },
    usePercentages: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    /**
     * All the different colors mapped to one array
     */
    amountColors() {
      const agreements = [{ amount: this.fallbackAmount }, ...this.agreements];
      const uniqueAmounts = [...new Set(agreements.map(({ amount }) => `${amount}`))];

      return uniqueAmounts.map((amount, index) => ({
        amount,
        color: this.colors[index],
      }));
    },
    days() {
      return Array.from({ length: 8 }, (_, i) => this.getDayGrid(i));
    },
    hasErrors() {
      return this.days.findIndex((day) => {
        return day[0].exceedsMaxPercentage === true;
      }) !== -1;
    },
  },
  methods: {
    /**
     * convert a timestring (00:00) to minutes from the beginning of the day
     *
     * @param {string} time
     * @returns int
     */
    timeToMinutes(field, time) {
      if (field === 'to' && (time === '00:00' || time === '24:00')) return 1440;

      const [hours, minutes] = time.split(':');
      return (parseInt(hours) * 60) + parseInt(minutes);
    },
    /**
     * calculate the duration of a single agreement compared to a whole day in percentages
     *
     * @param {Object} agreement
     */
    calculatePercentage(agreement) {
      const minutesInDay = 24 * 60;

      const fromInMinutes = this.timeToMinutes('from', agreement.from || '00:00');
      const toInMinutes = this.timeToMinutes('to', agreement.to || '00:00');
      const duration = toInMinutes - fromInMinutes;

      return duration / minutesInDay * 100;
    },
    /**
     * Get all the agreements for one day ordered and with percentages
     *
     * @param {Number} dayNumber (0 = festive day, 1 = monday, 7 = sunday)
     * @returns []
     */
    getDayGrid(dayNumber) {
      let totalPercentage = 0;
      const percentages = [];
      const sortedDayAgreements = this.agreements
        .filter(agreement => agreement.days.includes(dayNumber))
        .sort((a, b) => a.from.localeCompare(b.from));

      // if there is nothing set for this day, the whole day is the fallbackAmount
      if (sortedDayAgreements.length === 0) {
        return [{
          percentage: 100,
          amount: this.fallbackAmount,
        }];
      }

      // If the first record is not at the start of the day, fill the day with the fallbackAmount
      const firstAgreement = sortedDayAgreements[0];
      if (firstAgreement.from !== '00:00') {
        const percentage = this.calculatePercentage({
          from: '00:00',
          to: firstAgreement.from,
        });

        totalPercentage += Math.abs(percentage);

        percentages.push({
          percentage: Math.abs(percentage),
          amount: this.fallbackAmount,
        });
      }

      sortedDayAgreements.forEach((currentAgreement, index, agreements) => {
        const nextAgreement = agreements[index + 1];
        const percentage = this.calculatePercentage(currentAgreement);

        totalPercentage += Math.abs(percentage);

        percentages.push({
          percentage: Math.abs(percentage),
          amount: currentAgreement.amount,
        });

        // is there a gap between this agreement and the next? then fill it with the fallbackAmount
        if (nextAgreement && currentAgreement.to !== nextAgreement.from) {
          const percentage = this.calculatePercentage({
            from: currentAgreement.to,
            to: nextAgreement.from,
          });

          totalPercentage += Math.abs(percentage);
          percentages.push({
            percentage: Math.abs(percentage),
            amount: this.fallbackAmount,
          });
        }

      });

      // If the last record is not at the end of the day, fill the day with the fallbackAmount
      const lastAgreement = sortedDayAgreements[sortedDayAgreements.length - 1];
      if (lastAgreement.to !== '00:00' && lastAgreement.to !== '00:00') {
        const percentage = this.calculatePercentage({
          from: sortedDayAgreements[sortedDayAgreements.length - 1].to,
          to: '00:00',
        });

        totalPercentage += percentage;

        percentages.push({
          percentage,
          amount: this.fallbackAmount,
        });
      }

      if (Math.round(totalPercentage) > 100) {
        return [{
          exceedsMaxPercentage: true,
          percentage: 100,
          amount: this.fallbackAmount,
        }];
      }

      return percentages;
    },

    getColorByAmount(amount) {
      if (amount === null || typeof amount === 'undefined') {
        return;
      }
      const foundAmount = this.amountColors.find(obj => obj.amount.toString() === amount.toString());

      return `${foundAmount.color} ${this.colorVariant}`;
    },
    toHourDisplayText(hour) {
      hour -= 1; // because v-for starts with 1
      let hourPrefix = '';

      if (hour < 10) {
        hourPrefix = '0';
      }

      if (hour === 24) {
        return '00:00';
      }
      
      return `${hourPrefix}${hour}:00`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";

:deep(.agreement-wrapper) {
  position: relative;

  .time-grid {
    pointer-events: none;
    position:       absolute;
    top:            0;
    left:           0;
    right:          0;
    bottom:         0;

    .hour {
      height:        28px;
      border-right:  1px solid rgba(255, 255, 255, 0.3);
      border-bottom: 1px solid rgb(255, 255, 255);
    }
  }

  .agreement-grid {
    .error {
      background: repeating-linear-gradient(
                          45deg,
                          #d32f2f,
                          #d32f2f 10px,
                          #b71c1c 10px,
                          #b71c1c 20px
                  );
    }
  }

  .agreement {
    height: 28px;
  }
}

.hour-legend {
  margin-left: -2.9%;
}

.week-legend {
  .weekday:nth-child(odd) {
    background-color: $oddTableRow;
  }

  .weekday {
    height: 28px;
  }
}

.legend-block {
  min-width: 50px;
  height:    50px;
}

.fill-width {
  width: 100%;
}
</style>
