import Model from '@/application/models/model.js';
import priceAgreementType from '@/application/enums/priceAgreementType.js';
import temporaryWorkerSalesAgreementType
  from '@/application/enums/temporaryWorkerSalesAgreementType.js';
import cloneDeep from 'lodash.clonedeep';

class PriceAgreement extends Model {
  id = 0;
  from = '';
  priceAgreementTypeId = 1;
  isEditable = true;
  purchaseAgreementTypeIdIsEditable = true;
  premiumAgreement = {
    premiumAgreementTypeId: 1,
    grid: [],
  };
  purchaseAgreement = {
    purchaseAgreementTypeId: 1,
    rate: 0,
    commutingKilometerAllowance: null,
    businessKilometerAllowance: null,
  };
  salesAgreement = {
    premiumAgreementTypeId: 3,
    selfEmployedSalesAgreementTypeId: 1,
    selfEmployedSalesAgreementRateTypeId: 1,
    allowanceTypeId: 3,
    amount: 0,
    temporaryWorkerCostPriceFactor: null,
    grid: [],
    tradeNames: [],
  };

  mapForRequest() {
    const amountFactor = this.priceAgreementTypeId === priceAgreementType.TEMPORARY_WORKER && this.salesAgreement.temporaryWorkerSalesAgreementTypeId === temporaryWorkerSalesAgreementType.CONVERSION_FACTOR ? 10000 : 100;
    return {
      from: this.from,
      premiumAgreement: {
        premiumAgreementTypeId: this.premiumAgreement.premiumAgreementTypeId,
        grid: this.mapPremiumAgreementGridForRequest(this.premiumAgreement.grid),
      },
      purchaseAgreement: {
        purchaseAgreementTypeId: this.purchaseAgreement.purchaseAgreementTypeId,
        rate: Math.round(this.purchaseAgreement.rate * 100),
        commutingKilometerAllowance: parseFloat(this.purchaseAgreement.commutingKilometerAllowance) ? Math.round(this.purchaseAgreement.commutingKilometerAllowance * 100) : null,
        businessKilometerAllowance: parseFloat(this.purchaseAgreement.businessKilometerAllowance) ? Math.round(this.purchaseAgreement.businessKilometerAllowance * 100) : null,
      },
      salesAgreement: {
        ...this.salesAgreement,
        grid: this.mapSalesAgreementGridForRequest(this.salesAgreement.grid),
        amount: Math.round(this.salesAgreement.amount * amountFactor),
        temporaryWorkerCostPriceFactor: this.salesAgreement.temporaryWorkerSalesAgreementTypeId === temporaryWorkerSalesAgreementType.FIXED_INCREMENT ? Math.round(this.salesAgreement.temporaryWorkerCostPriceFactor * 10000) : null,
        allowanceAmount: Math.round(this.salesAgreement.allowanceAmount * amountFactor),
        tradeNameIds: this.salesAgreement.tradeNames.map(tradeName => tradeName.id),
      },
    };
  }

  mapResponse(data) {
    const amountFactor = data.priceAgreementTypeId === priceAgreementType.TEMPORARY_WORKER && data.salesAgreement.temporaryWorkerSalesAgreementTypeId === temporaryWorkerSalesAgreementType.CONVERSION_FACTOR ? 10000 : 100;
    const amountDigits = data.priceAgreementTypeId === priceAgreementType.TEMPORARY_WORKER && data.salesAgreement.temporaryWorkerSalesAgreementTypeId === temporaryWorkerSalesAgreementType.CONVERSION_FACTOR ? 4 : 2;

    if (data.premiumAgreement) {
      data.premiumAgreement.grid = this.mapPremiumAgreementGridResponse(data.premiumAgreement.grid);
    }
    if (data.purchaseAgreement) {
      data.purchaseAgreement.rate = (data.purchaseAgreement.rate / 100).toFixed(2);
      data.purchaseAgreement.commutingKilometerAllowance = data.purchaseAgreement.commutingKilometerAllowance ? (data.purchaseAgreement.commutingKilometerAllowance / 100).toFixed(2) || 0 : null;
      data.purchaseAgreement.businessKilometerAllowance = data.purchaseAgreement.businessKilometerAllowance ? (data.purchaseAgreement.businessKilometerAllowance / 100).toFixed(2) || 0 : null;
    }
    if (data.salesAgreement) {
      data.salesAgreement.grid = this.mapSalesAgreementGridResponse(data.salesAgreement.grid);
      data.salesAgreement.amount = (data.salesAgreement.amount / amountFactor).toFixed(amountDigits);
      data.salesAgreement.temporaryWorkerCostPriceFactor = ((data.salesAgreement.temporaryWorkerCostPriceFactor || 10000) / 10000).toFixed(4);
      data.salesAgreement.allowanceAmount = (data.salesAgreement.allowanceAmount / amountFactor).toFixed(amountDigits);
    }
    return super.mapResponse(data);
  }

  mapPremiumAgreementGridResponse(grid) {
    grid = grid.map(premiumAgreementGrid => {
      premiumAgreementGrid.amount = premiumAgreementGrid.amount / 100;
      return premiumAgreementGrid;
    });
    return grid;
  }

  mapPremiumAgreementGridForRequest(grid) {
    let gridForRequest = cloneDeep(grid);
    gridForRequest = gridForRequest.map(premiumAgreementGrid => {
      premiumAgreementGrid.amount = Math.round(premiumAgreementGrid.amount * 100);
      return premiumAgreementGrid;
    });
    return gridForRequest;
  }

  mapSalesAgreementGridResponse(grid) {
    grid = grid.map(salesAgreementGrid => {
      salesAgreementGrid.amount = salesAgreementGrid.amount / 100;
      return salesAgreementGrid;
    });
    return grid;
  }

  mapSalesAgreementGridForRequest(grid){
    let gridForRequest = cloneDeep(grid);
    gridForRequest = gridForRequest.map(gridLine => {
      gridLine.amount = Math.round(gridLine.amount * 100);
      return gridLine;
    });
    return gridForRequest;
  }
}

export default PriceAgreement;
