<template>
  <div class="pa-6">
    <v-row
      no-gutters
      align="center"
    >
      <v-col>
        <slot name="header" />
      </v-col>
      <v-col cols="auto">
        <v-row
          align="center"
          no-gutters
        >
          <slot name="flow-actions" />
        </v-row>
      </v-col>
    </v-row>
    <slot v-slot />
  </div>
</template>

<script>
export default {
  name: 'PageLayout',
};
</script>
