<template>
  <PageLayout>
    <template #header>
      <k-title v-if="priceAgreement">
        {{ priceAgreement.name }}
      </k-title>
    </template>
    <template #flow-actions>
      <v-btn
        depressed
        tile
        :to="{ name: 'employer.priceAgreement.periods' }"
        color="primary"
      >
        {{ $tc('priceAgreement.periods.title', 2) }}
      </v-btn>
    </template>
    <template
      v-if="employer && priceAgreement"
      #default
    >
      <k-display-group language-prefix="priceAgreement.fields">
        <v-row
          justify="center"
          align="center"
        >
          <v-btn
            icon
            exact
            :disabled="!priceAgreement.previousPriceAgreementDetailId"
            @click="previousPriceAgreement"
          >
            <v-icon small>
              $prev
            </v-icon>
          </v-btn>
          <div class="mx-2">
            {{ dateText }}
          </div>
          <v-btn
            icon
            exact
            :disabled="!priceAgreement.nextPriceAgreementDetailId"
            @click="nextPriceAgreement"
          >
            <v-icon small>
              $next
            </v-icon>
          </v-btn>
        </v-row>
        <v-row>
          <v-col>
            <k-text-display field="priceAgreementTypeId">
              {{ priceAgreementTypeText }}
            </k-text-display>
          </v-col>
        </v-row>
        <v-row v-if="priceAgreement.purchaseAgreement">
          <v-col>
            <h3 class="text-h3 primary--text text--darken-4 mb-3">
              {{
                $t('priceAgreement.periods.tabs.purchase')
              }}
            </h3>
            <VDivider />
            <Purchase :purchase-agreement="priceAgreementModel.purchaseAgreement" />
          </v-col>
        </v-row>
        <v-row v-if="priceAgreement.premiumAgreement">
          <v-col>
            <h3 class="text-h3 primary--text text--darken-4 mb-3">
              {{ $t('priceAgreement.periods.sections.purchasePremium') }}
            </h3>
            <VDivider />
            <PurchasePremium :premium-agreement="priceAgreementModel.premiumAgreement" />
          </v-col>
        </v-row>
        <v-row v-if="priceAgreement.salesAgreement">
          <v-col>
            <h3 class="text-h3 primary--text text--darken-4 mb-3">
              {{ $t('priceAgreement.periods.tabs.sales') }}
            </h3>
            <VDivider />
            <Sales :sales-agreement="priceAgreementModel.salesAgreement" />
          </v-col>
        </v-row>
        <v-row v-if="priceAgreement.salesAgreement.grid">
          <v-col>
            <h3 class="text-h3 primary--text text--darken-4 mb-3">
              {{ $t(`priceAgreement.periods.sections.${priceAgreementIsZZP ? 'salesPremiumZZP' : 'salesPremiumUZK'}`) }}
            </h3>
            <VDivider />
            <SalesPremium :premium-agreement="priceAgreementModel.salesAgreement" />
          </v-col>
        </v-row>
        <v-row v-if="priceAgreement.purchaseAgreement">
          <v-col>
            <h3 class="text-h3 primary--text text--darken-4 mb-3">
              {{ $t('priceAgreement.periods.tabs.compensation') }}
            </h3>
            <VDivider />
            <Compensation :purchase-agreement="priceAgreementModel.purchaseAgreement" />
          </v-col>
        </v-row>
      </k-display-group>
    </template>
  </PageLayout>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import PageLayout from '@/components/layout/PageLayout.vue';
import KTitle from '@/components/layout/KTitle.vue';
import { overview } from '@/modules/priceAgreements/api/priceAgreement.js';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import priceAgreementType from '@/application/enums/priceAgreementType.js';
import Purchase from '@/modules/priceAgreements/components/showTabs/Purchase.vue';
import PurchasePremium from '@/modules/priceAgreements/components/showTabs/PurchasePremium.vue';
import SalesPremium from '@/modules/priceAgreements/components/showTabs/SalesPremium.vue';
import Sales from '@/modules/priceAgreements/components/showTabs/Sales.vue';
import PriceAgreementPeriod from '@/application/models/PriceAgreementPeriod.js';
import dayjs from '@/plugins/dayjs';
import Compensation from '@/modules/priceAgreements/components/showTabs/Compensation.vue';

export default {
  name: 'Details',
  components: {
    Compensation,
    Sales,
    PurchasePremium,
    SalesPremium,
    Purchase,
    KDisplayGroup,
    KTextDisplay,
    KTitle,
    PageLayout,
  },
  props: {
    employer: {
      type: Object,
    },
    getEmployer: {
      type: Function,
    },
  },
  data: () => ({
    priceAgreement: null,
  }),
  computed: {
    priceAgreementIsZZP() {
      return this.priceAgreement.priceAgreementTypeId === priceAgreementType.SELF_EMPLOYED;
    },
    priceAgreementTypeText() {
      return this.$t(`priceAgreement.priceAgreementType.${Object.keys(priceAgreementType)
        .find(key => priceAgreementType[key] === this.priceAgreement?.priceAgreementTypeId)}`);
    },
    /**
     * it's a bit ugly but since the model handles all the numbering formats i use the model here,
     * but some values do not exist in this model, since they are not used in the form.
     * therefore i've made a priceAgreement and a priceAgreement model. Suggestions are welcome!
     */
    priceAgreementModel() {
      return new PriceAgreementPeriod().mapResponse(this.priceAgreement);
    },
    dateText() {
      return `${dayjs(this.priceAgreement.from).format('LL')} - ${this.priceAgreement.to ? dayjs(this.priceAgreement.to)
        .format('LL') : this.$t('global.timeUnits.current')}`;
    },
  },
  watch: {
    '$route.params.priceAgreementDetailId'() {
      this.fetchPriceAgreement();
    },
  },
  async created() {
    await this.fetchPriceAgreement();
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'employer.index' },
        text: this.$tc('employer.title', 2),
      },
      {
        exact: true,
        to: { name: 'employer.show' },
        text: this.employer.name,
      },
      {
        exact: true,
        to: { name: 'employer.priceAgreement' },
        text: this.$tc('priceAgreement.title', 2),
      },
      {
        exact: true,
        to: { name: 'employer.priceAgreement.show' },
        text: this.priceAgreement?.name,
      },
    ]);
  },
  methods: {
    async fetchPriceAgreement() {
      const response = await overview(this.$route.params.priceAgreementId, this.$route.params.priceAgreementDetailId);
      this.priceAgreement = response.data.data;
    },
    nextPriceAgreement() {
      this.$router.push({
        name: 'employer.priceAgreement.period',
        params: {
          ...this.$route.params,
          priceAgreementDetailId: this.priceAgreement.nextPriceAgreementDetailId,
        },
      });
    },
    previousPriceAgreement() {
      this.$router.push({
        name: 'employer.priceAgreement.period',
        params: {
          ...this.$route.params,
          priceAgreementDetailId: this.priceAgreement.previousPriceAgreementDetailId,
        },
      });
    },
  },
};
</script>
