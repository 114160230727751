import { destroy, get, post, put } from '@/application/api/implementations/app';
import { getPaginated as getPaginatedOld } from '@/application/api/implementations/app/wrapper.js';
import { getPaginated } from '@/application/api/getPaginated';

export const index = (employerId, ...args) => getPaginatedOld(`employer/${employerId}/price-agreement`, ...args);

export const create = (employerId, priceAgreement) => post(`employer/${employerId}/price-agreement`, priceAgreement);

export const show = (priceAgreementId) => get(`price-agreement/${priceAgreementId}`);

export const overview = (priceAgreementId, priceAgreementDetailId) => get(`price-agreement/${priceAgreementId}/overview`, { params: { priceAgreementDetailId } });

export const update = (priceAgreement) => put(`price-agreement/${priceAgreement.id}`, priceAgreement);

export const remove = (priceAgreementId) => destroy(`price-agreement/${priceAgreementId}`);

export const autocomplete = (employerId, data) => getPaginated(`employer/${employerId}/price-agreement/autocomplete`, data);

export const copy = (priceAgreementId) => get(`price-agreement/${priceAgreementId}/copy`);
