<template>
  <k-display-group language-prefix="priceAgreement.periods.fields.purchaseAgreement">
    <k-text-display
      field="commutingKilometerAllowance"
      :value="formatValue(purchaseAgreement.commutingKilometerAllowance)"
    />
    <k-text-display
      field="businessKilometerAllowance"
      :value="formatValue(purchaseAgreement.businessKilometerAllowance)"
    />
  </k-display-group>
</template>

<script>
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';

export default {
  name: 'Compensation',
  components: {
    KTextDisplay,
    KDisplayGroup,
  },
  props: {
    purchaseAgreement: {
      type: Object,
    },
  },
  methods: {
    formatValue(value) {
      return value ? this.$n(value, 'currency') : this.$t('global.notApplicable');
    },
  },
};
</script>
