<template>
  <div>
    <k-display-group language-prefix="priceAgreement.periods.fields.premiumAgreement">
      <k-text-display
        field="premiumAgreementTypeId"
        :value="premiumAgreementTypeText"
      />

      <AgreementGrid
        v-if="premiumAgreement.premiumAgreementTypeId !== premiumAgreementType.NO_PREMIUM"
        :agreements="premiumAgreement.grid"
        :fallback-amount="premiumAgreement.premiumAgreementTypeId === premiumAgreementType.PERCENTAGE ? 100 : 0"
        :use-percentages="premiumAgreement.premiumAgreementTypeId === premiumAgreementType.PERCENTAGE"
      />
    </k-display-group>
  </div>
</template>

<script>
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import { premiumAgreementType } from '@/application/enums/premiumAgreementType.js';
import AgreementGrid from '@/modules/priceAgreements/components/AgreementGrid.vue';

export default {
  name: 'PurchasePremium',
  components: {
    AgreementGrid,
    KTextDisplay,
    KDisplayGroup,
  },
  props: {
    premiumAgreement: {
      type: Object,
    },
  },
  computed: {
    premiumAgreementType: () => premiumAgreementType,
    premiumAgreementTypeText() {
      return this.$t(`priceAgreement.premiumAgreementType.${Object.keys(premiumAgreementType)
        .find(key => premiumAgreementType[key] === this.premiumAgreement.premiumAgreementTypeId)}`);
    },
  },
};
</script>
