<template>
  <k-display-group language-prefix="priceAgreement.periods.fields.salesAgreement">
    <KTextDisplay
      field="selfEmployedSalesAgreementTypeId"
      :value="selfEmployedSalesAgreementTypeText"
    />
    <KTextDisplay
      field="selfEmployedSalesAgreementRateTypeId"
      :value="selfEmployedSalesAgreementRateTypeText"
    />
    <KTextDisplay
      :field="amountFieldName"
      :value="amountFieldText"
    />
    <KTextDisplay
      v-if="showAllowance"
      field="allowanceTypeId"
      :value="allowanceTypeText"
    />
    <KTextDisplay
      v-if="salesAgreement.allowanceTypeId === allowanceType.ALLOWANCE_TYPE_CUSTOM"
      field="allowanceAmount"
      :value="$n(salesAgreement.allowanceAmount/100, 'percent')"
    />
  </k-display-group>
</template>

<script>
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import selfEmployedSalesAgreementType from '@/application/enums/selfEmployedSalesAgreementType.js';
import selfEmployedSalesAgreementRateType from '@/application/enums/selfEmployedSalesAgreementRateType.js';
import allowanceType from '@/application/enums/allowanceType.js';

export default {
  name: 'SelfEmployedSales',
  components: {
    KTextDisplay,
    KDisplayGroup,
  },
  props: {
    salesAgreement: {
      type: Object,
    },
  },
  computed: {
    selfEmployedSalesAgreementRateType() {
      return selfEmployedSalesAgreementRateType;
    },
    allowanceType: () => allowanceType,
    showAllowance() {
      return this.salesAgreement.selfEmployedSalesAgreementRateTypeId === selfEmployedSalesAgreementRateType.PERCENTAGE;
    },
    selfEmployedSalesAgreementTypeText() {
      const key = Object.keys(selfEmployedSalesAgreementType)
        .find(key => selfEmployedSalesAgreementType[key] === this.salesAgreement.selfEmployedSalesAgreementTypeId);
      return this.$t(`priceAgreement.selfEmployedSalesAgreementType.${key}`);
    },
    selfEmployedSalesAgreementRateTypeText() {
      const key = Object.keys(selfEmployedSalesAgreementRateType)
        .find(key => selfEmployedSalesAgreementRateType[key] === this.salesAgreement.selfEmployedSalesAgreementRateTypeId);
      return this.$tc(`priceAgreement.selfEmployedSalesAgreementRateType.${key}`, this.salesAgreement.selfEmployedSalesAgreementTypeId);
    },
    amountFieldName() {
      switch (this.salesAgreement.selfEmployedSalesAgreementRateTypeId) {
      case selfEmployedSalesAgreementRateType.PERCENTAGE:
        return 'percentage';
      case selfEmployedSalesAgreementRateType.SALES_RATE:
        return 'defaultAmount';
      case selfEmployedSalesAgreementRateType.FIXED_INCREMENT:
      default:
        return 'defaultIncrement';
      }
    },
    amountFieldText() {
      switch (this.salesAgreement.selfEmployedSalesAgreementRateTypeId) {
      case selfEmployedSalesAgreementRateType.PERCENTAGE:
        return this.$n(this.salesAgreement.amount / 100, 'percent');
      case selfEmployedSalesAgreementRateType.SALES_RATE:
      case selfEmployedSalesAgreementRateType.FIXED_INCREMENT:
      default:
        return this.$n(this.salesAgreement.amount, 'currency');
      }
    },
    allowanceTypeText() {
      const key = Object.keys(allowanceType)
        .find(key => allowanceType[key] === this.salesAgreement.allowanceTypeId);
      return this.$t(`priceAgreement.allowanceType.${key}`);
    },
  },
};
</script>
