<template>
  <div>
    <SelfEmployedSales
      v-if="isSelfEmployed"
      :sales-agreement="salesAgreement"
    />
    <TemporaryWorkerSales
      v-else
      :sales-agreement="salesAgreement"
    />
    <k-display-group language-prefix="priceAgreement.periods.fields.salesAgreement">
      <k-text-display field="tradeNames">
        <span
          v-for="(tradeName, idx) in salesAgreement.tradeNames"
          :key="idx"
        >
          {{ tradeName.name }}<span v-if="idx < salesAgreement.tradeNames.length - 1">,&nbsp;</span>
        </span>
      </k-text-display>
    </k-display-group>
  </div>
</template>

<script>
import SelfEmployedSales from '@/modules/priceAgreements/components/showTabs/SelfEmployedSales.vue';
import TemporaryWorkerSales from '@/modules/priceAgreements/components/showTabs/TemporaryWorkerSales.vue';
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';

export default {
  name: 'Sales',
  components: {
    KTextDisplay,
    KDisplayGroup,
    TemporaryWorkerSales,
    SelfEmployedSales,
  },
  props: {
    salesAgreement: {
      type: Object,
    },
  },
  computed: {
    isSelfEmployed() {
      return !!this.salesAgreement.selfEmployedSalesAgreementRateTypeId;
    },
  },
};
</script>
